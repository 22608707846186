@import 'tailwindcss/base.css';
@import 'tailwindcss/utilities.css';
@import 'tailwindcss/components.css';
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-thin-straight/css/uicons-thin-straight.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-regular-straight/css/uicons-regular-straight.css');
@import 'boxicons/css/boxicons.min.css';
@import 'prismjs/themes/prism.css';

*::selection {
  @apply bg-main/20;
}

html, body {
  @apply overflow-x-hidden;
}

html {
  @apply bg-light-10;
}

main {
  @apply min-h-[100vh];
}
